// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-add-js": () => import("./../src/pages/add.js" /* webpackChunkName: "component---src-pages-add-js" */),
  "component---src-pages-cashback-js": () => import("./../src/pages/cashback.js" /* webpackChunkName: "component---src-pages-cashback-js" */),
  "component---src-pages-cronjob-js": () => import("./../src/pages/cronjob.js" /* webpackChunkName: "component---src-pages-cronjob-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-js": () => import("./../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-my-product-js": () => import("./../src/pages/my_product.js" /* webpackChunkName: "component---src-pages-my-product-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-view-js": () => import("./../src/pages/view.js" /* webpackChunkName: "component---src-pages-view-js" */)
}

